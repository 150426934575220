import React, { useState, useRef, useEffect } from "react";
import Dropdown from "../utils/Dropdown";

const default_r4me =
  "https://res.cloudinary.com/dykrzfpex/image/upload/v1584917661/Reserv4me_logo_main_page_kwksny.png";

const HeaderTop = ({ logo = default_r4me, hwebsite, hphone, h_url }) => {
  console.log("🚀 ~ file: header.js ~ line 11 ~ HeaderTop ~ logo", logo);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      <header className="absolute w-full z-30 bg-blue-500">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 ">
          <div className="flex items-center justify-between h-20 ">
            {/* Site branding */}
            <div className="shrink-0 mr-5">
              {/* Logo */}
              <a
                href={logo === default_r4me ? "/" : logo === null ? "/" : h_url}
                className={
                  logo === default_r4me
                    ? "block bg-zinc-50"
                    : "flex items-center"
                }
              >
                <img
                  src={
                    logo === null
                      ? "https://res.cloudinary.com/dykrzfpex/image/upload/v1641073941/Logo_20Stock_tuqjdg.png"
                      : logo
                  }
                  className={
                    logo === default_r4me
                      ? "object-contain h-10"
                      : "object-contain h-20"
                  }
                ></img>
                <h1
                  className={
                    logo === default_r4me
                      ? "hidden"
                      : "ml-5 font-semibold tracking-tight text-white text-sm md:text-lg"
                  }
                >
                  {hwebsite}
                </h1>
              </a>
            </div>
            {
              // <nav className="hidden md:flex md:grow">
              //   <ul className="flex flex-grow justify-end flex-wrap items-center">
              //     <Dropdown title="Products">
              //       {/* 2nd level: hover */}
              //       <li>
              //         <a
              //           href="/products/Reserv4me"
              //           className="font-medium text-sm text-white hover:text-blue-800 flex py-2 px-4 leading-tight"
              //         >
              //           Reserv4me
              //         </a>
              //         <a
              //           href="/products/Reserv4meCRM"
              //           className="font-medium text-sm text-white hover:text-blue-800 flex py-2 px-4 leading-tight"
              //         >
              //           Reserv4me CRM
              //         </a>
              //         <a
              //           href="/products/kiosk"
              //           className="font-medium text-sm text-white hover:text-blue-800 flex py-2 px-4 leading-tight"
              //         >
              //           Kiosk
              //         </a>
              //         <a
              //           href="/contact"
              //           className="font-medium text-sm text-white hover:text-blue-800 flex py-2 px-4 leading-tight"
              //         >
              //           Custom Websites
              //         </a>
              //       </li>
              //     </Dropdown>
              //     <li>
              //       <a
              //         href="/about"
              //         className="text-white hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
              //       >
              //         About
              //       </a>
              //     </li>
              //     <li>
              //       <a
              //         href="/blog"
              //         className="text-white hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
              //       >
              //         Blog
              //       </a>
              //     </li>
              //     <li>
              //       <a
              //         href="/testimonials"
              //         className="text-white hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
              //       >
              //         Testimonials
              //       </a>
              //     </li>
              //     {/* 1st level: hover */}
              //   </ul>
              // </nav>
              // <div className="md:hidden">
              //   {/* Hamburger button */}
              //   <button
              //     ref={trigger}
              //     className={`hamburger ${mobileNavOpen && "active"}`}
              //     aria-controls="mobile-nav"
              //     aria-expanded={mobileNavOpen}
              //     onClick={() => setMobileNavOpen(!mobileNavOpen)}
              //   >
              //     <span className="sr-only">Menu</span>
              //     <svg
              //       className="w-6 h-6 fill-current text-black hover:text-gray-200 transition duration-150 ease-in-out"
              //       viewBox="0 0 24 24"
              //       xmlns="http://www.w3.org/2000/svg"
              //     >
              //       <rect y="4" width="24" height="2" rx="1" />
              //       <rect y="11" width="24" height="2" rx="1" />
              //       <rect y="18" width="24" height="2" rx="1" />
              //     </svg>
              //   </button>
              //   {/*Mobile navigation */}
              //   <nav
              //     id="mobile-nav"
              //     ref={mobileNav}
              //     className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
              //     style={
              //       mobileNavOpen
              //         ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 }
              //         : { maxHeight: 0, opacity: 0.8 }
              //     }
              //   >
              //     <ul className="bg-gray-800 px-4 py-2">
              //       <li className="py-2 my-2 border-t border-b border-gray-700">
              //         <span className="flex text-white py-2">Products</span>
              //         <ul className="pl-4">
              //           <li>
              //             <a
              //               href="/products/Reserv4me"
              //               className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
              //             >
              //               Reserv4me
              //             </a>
              //             <a
              //               href="/products/Reserv4meCRM"
              //               className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
              //             >
              //               Reserv4me CRM
              //             </a>
              //             <a
              //               href="/products/kiosk"
              //               className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
              //             >
              //               Kiosk
              //             </a>
              //             <a
              //               href="/contact"
              //               className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
              //             >
              //               Custom Websites
              //             </a>
              //           </li>
              //         </ul>
              //       </li>
              //       <li>
              //         <a
              //           href="/about"
              //           className="flex text-white hover:text-gray-200 py-2"
              //         >
              //           About us
              //         </a>
              //       </li>
              //       <li className="py-2 my-2 border-t border-b border-gray-700">
              //         <span className="flex text-white py-2">Support</span>
              //         <ul className="pl-4">
              //           <li>
              //             <a
              //               href="/contact"
              //               className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
              //             >
              //               Contact us
              //             </a>
              //           </li>
              //         </ul>
              //       </li>
              //       {/*<li>
              //       <a
              //         href="/signin"
              //         className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center"
              //       >
              //         Sign in
              //       </a>
              //     </li>
              //     <li>
              //       <a
              //         href="/signup"
              //         className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out"
              //       >
              //         Sign up
              //       </a>
              //     </li>*/}
              //     </ul>
              //   </nav>
              // </div>
            }
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderTop;
