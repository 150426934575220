import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import RoomCarousel from "../Havailability/RoomCarousel";
import PaymenttypeForm from "./PaymenttypeForm";
import FormClientbuilder from "./FormClientbuilder";
import moment from "moment";

class ReservationMaking extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.handlemeailchange = this.handlemeailchange.bind(this);
    this.paymentroute = this.paymentroute.bind(this);
    this.state = {
      roomobject: [],
      datagathered: false,
      loading: false,
      //open: false,
      key: 1, //Controls the tab that is open

      valuefname: "",
      valuelname: "",
      valuephnum: "",
      valueemail: "", //stores the value of client email
      valueemailconfirm: "", //stores the value of client email
      valueadditionalRequests: "",
      requestLength: 0,

      radiobtnselected: "Paylater", //controls which payment method was selected and which buttons and form should be shown
      Fnamevalidation: null, //controls validation of Client
      LnameValidation: null, //controls validation of Client
      PNumbValidation: null, //controls validation of Client
      originalemailstate: null, //controls validation of Client
      toconfirmemailstate: null, //controls validation of Client
      hasreviewed: false, //controls validation of Client

      Unauthorized: false, //controls when people's session has expired and need to start again all over
      allow_setup_pay: false,
      due_date: moment(),
    };
  }
  componentDidMount() {
    ////console.log("test",window.sessionStorage.getItem('RoomBooked')===true)

    if (window.sessionStorage.getItem("RoomBooked") === "true") {
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      window.sessionStorage.removeItem("RoomBooked");
      //window.sessionStorage.clear();
      window.location =
        process.env.NODE_ENV === "development"
          ? `http://localhost:3001/${this.props.match.params.Location}/${
              this.props.match.params.Hname
            }/avail?CIdate=${
              today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}&COdate=${
              tomorrow.getMonth() + 1
            }/${tomorrow.getDate()}/${tomorrow.getFullYear()}&GA=2&GCH=0`
          : `https://www.reserv4me.com/${this.props.match.params.Location}/${
              this.props.match.params.Hname
            }/avail?CIdate=${
              today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}&COdate=${
              tomorrow.getMonth() + 1
            }/${tomorrow.getDate()}/${tomorrow.getFullYear()}&GA=2&GCH=0`;
      ////console.log("I was supposed to send you back")
    } else {
      if (this.state.datagathered === false) {
        fetch(
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/reservation2"
            : "https://apiv1.reserv4me.com/reservation2",
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: window.sessionStorage.getItem("token"),
            },
            body: JSON.stringify({
              CIdate: this.props.location.search
                .split("?")[1]
                .split("&")[0]
                .split("=")[1],
              COdate: this.props.location.search
                .split("?")[1]
                .split("&")[1]
                .split("=")[1],
              hotelname: this.props.match.params.Hname,
              ADg: this.props.location.search
                .split("?")[1]
                .split("&")[2]
                .split("=")[1],
              CHg: this.props.location.search
                .split("?")[1]
                .split("&")[3]
                .split("=")[1],
              roomid: this.props.location.search
                .split("?")[1]
                .split("&")[4]
                .split("=")[1],
              tripid:
                window.sessionStorage.getItem("trid") === null
                  ? undefined
                  : window.sessionStorage.getItem("trid"),
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data !== undefined) {
              let due_date = 0;
              let allow_setup_pay = false;
              if (data[0].token) {
                window.sessionStorage.setItem("token", data[0].token);
                window.sessionStorage.setItem("trid", data[0].tripid);
              }
              if (data[0].allow_setup_pay === true) {
                due_date = moment([
                  this.props.location.search
                    .split("?")[1]
                    .split("&")[0]
                    .split("=")[1]
                    .split("/")[2],
                  this.props.location.search
                    .split("?")[1]
                    .split("&")[0]
                    .split("=")[1]
                    .split("/")[0] - 1,
                  this.props.location.search
                    .split("?")[1]
                    .split("&")[0]
                    .split("=")[1]
                    .split("/")[1],
                ]);
                ////console.log("due date",due_date.diff(moment(),"days"))
                if (
                  due_date.diff(moment(), "days") > data[0].due_date_payment
                ) {
                  // //console.log("we can allow it")
                  allow_setup_pay = true;
                } else {
                  ////console.log("not possible")
                  allow_setup_pay = false;
                }
              }
              // //console.log(due_date)
              this.setState(
                {
                  roomobject: data,
                  datagathered: true,
                  allow_setup_pay: allow_setup_pay,
                  due_date: moment(due_date).subtract(
                    data[0].due_date_payment,
                    "days"
                  ),
                  radiobtnselected:
                    allow_setup_pay === true ? "Paylater" : "Paynow",
                },
                () => {
                  ////console.log("result",(parseFloat(this.state.roomobject[0].total_w_tax).toFixed(2)-(parseFloat(this.state.roomobject[0].roomfirstnight)*(1+(this.state.roomobject[0].taxrate/100))).toFixed(2)))
                }
              );

              //remember that when updating the DOM if we say use my old way
            } //which was to call the method inside the html code (onLoad)is a wrong approach since once called and set the new state we are "loading"
          }); //the DOM again do an infinite loop is created, thus is better to call the method from the componentdidmount() so that it gets called once.
      }
    }
  }

  isboxempty = (successValidation) => {
    /* Check whether we have the correct boxes filled in the Client form
     */
    const original = this.state.valueemail;
    const toconfirm = this.state.valueemailconfirm;
    if (document.getElementById(successValidation.id).value === "") {
      //tovalidate.allgoodtxt=true
      ////console.log(successValidation.id,successValidation.storageval)
      ////console.log(successValidation.id,successValidation.storageval=3)
      this.setState(successValidation.txtboxValidation);
      return false;
      //document.getElementById(id).parentElement.className='form-group has-feedback has-error';
    } else if (document.getElementById(successValidation.id).value !== "") {
      this.setState(successValidation.greenValidation);
      //return(true)
      //remember that you need to get the events fot when all is good and to change it for the
      //emails so that they are agreeing
    }
    ////console.log(txtbox)
    if (successValidation.id === "formEmail") {
      ////console.log("I entered",/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(document.getElementById(id).value))
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(original)) {
        ////console.log(document.getElementById(id).value)
        return false;
      }
    }
    if (successValidation.id === "formConfirmEmail") {
      if (original !== toconfirm) {
        return false;
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(toconfirm)) {
        ////console.log(document.getElementById(id).value)
        return false;
      }
    }
  };

  testingboxes = (successValidation) => {
    /**
     * its the way to decide which test will be applied to which textbox
     */
    switch (successValidation.id) {
      case "formControlsFName": {
        return this.isboxempty(successValidation);
      }
      case "formControlsLName": {
        return this.isboxempty(successValidation);
      }
      case "formControlsPhoneNumb": {
        return this.isboxempty(successValidation);
      }
      case "formEmail": {
        return this.isboxempty(successValidation);
      }
      case "formConfirmEmail": {
        return this.isboxempty(successValidation);
      }
    }
  };
  //paymentroutecheck
  paymentroute(e) {
    ////console.log(e.target.id)

    this.setState({ hasreviewed: true, loading: true }); //this is needed to control the feedback of emails
    function Boxes(id, txtboxValidation, greenValidation) {
      //Constructor for Boxes Objects
      this.id = id;
      this.txtboxValidation = txtboxValidation; //warning object
      this.greenValidation = greenValidation; //success object
    }

    let fname = new Boxes(
      "formControlsFName",
      { Fnamevalidation: "warning" },
      { Fnamevalidation: "success" }
    ); //objects that describe textboxes
    let lname = new Boxes(
      "formControlsLName",
      { LnameValidation: "warning" },
      { LnameValidation: "success" }
    );
    let phn = new Boxes(
      "formControlsPhoneNumb",
      { PNumbValidation: "warning" },
      { PNumbValidation: "success" }
    );
    let email = new Boxes(
      "formEmail",
      { originalemailstate: "warning" },
      { originalemailstate: "success" }
    );
    let conemail = new Boxes(
      "formConfirmEmail",
      { toconfirmemailstate: "warning" },
      { toconfirmemailstate: "success" }
    );
    let checktxtb = [fname, lname, phn, email, conemail];

    let alltxtgood = true;
    for (
      let i = 0;
      i < checktxtb.length;
      i++ //applies test to the text boxes
    ) {
      let tempvar = this.testingboxes(checktxtb[i]);
      alltxtgood = tempvar === undefined ? alltxtgood : tempvar;
    }
    if (alltxtgood === false) {
      this.setState((prevState) => ({ key: 1, loading: !prevState }));
      window.scrollTo(0, 0);
      return false;
    } else {
      if (e.target.id === "ppbtn") {
        fetch(
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/payreq"
            : "https://apiv1.reserv4me.com/payreq",
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: window.sessionStorage.getItem("token"),
            },
            body: JSON.stringify({
              CIdate: this.props.location.search
                .split("?")[1]
                .split("&")[0]
                .split("=")[1],
              COdate: this.props.location.search
                .split("?")[1]
                .split("&")[1]
                .split("=")[1],
              hotelname: this.props.match.params.Hname,
              ADg: this.props.location.search
                .split("?")[1]
                .split("&")[2]
                .split("=")[1],
              CHg: this.props.location.search
                .split("?")[1]
                .split("&")[3]
                .split("=")[1],
              roomid: this.props.location.search
                .split("?")[1]
                .split("&")[4]
                .split("=")[1],
              tripid: this.props.location.search
                .split("?")[1]
                .split("&")[5]
                .split("=")[1],
              query: this.props.location.search.split("?")[1],
              fname: this.state.valuefname,
              lname: this.state.valuelname,
              phnum: this.state.valuephnum,
              email: this.state.valueemail,
            }),
          }
        )
          .then((data) => data.json())
          .then((res) => {
            window.location = res;
            /*this.props.history.push({
                    pathname: `/success?${res}`,
                    state: {
                        stripeenable:true
                    }
                })*/
          });
      } else if (e.target.id === "stripebtn" || e.target.id === "strbtnlater") {
        ////console.log("returning")
        return true;
      }
    }
  }
  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }
  handleSelected(key) {
    if (key === this.state.key) {
      this.setState({ key: 0 });
    } else {
      this.setState({ key });
    }
  }

  validationState = (typefor) => {
    //to validate we are using RegEx
    const original = this.state.valueemail;
    const toconfirm = this.state.valueemailconfirm;
    if (typefor === "original") {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(original) && original !== "") {
        return "success";
      } else if (original !== "") {
        return "error";
      } else if (
        this.state.hasreviewed &&
        this.state.originalemailstate !== "success"
      ) {
        return "error";
      }
    } else if (typefor === "toconfirm") {
      if (
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(toconfirm) &&
        original === toconfirm &&
        toconfirm !== ""
      ) {
        return "success";
      } else if (original !== toconfirm || toconfirm !== "") {
        return "error";
      } else if (
        this.state.hasreviewed &&
        this.state.originalemailstate !== "success"
      ) {
        return "error";
      }
    }
  };

  handlemeailchange(e) {
    if (e.target.id === "formEmail") {
      this.setState({ valueemail: e.target.value });
    } else if (e.target.id === "formConfirmEmail") {
      this.setState({ valueemailconfirm: e.target.value });
    } else if (e.target.id === "formControlsFName") {
      this.setState({ valuefname: e.target.value });
    } else if (e.target.id === "formControlsLName") {
      this.setState({ valuelname: e.target.value });
    } else if (e.target.id === "formControlsPhoneNumb") {
      this.setState({ valuephnum: e.target.value });
    } else if (e.target.id === "formAditionalRequest") {
      if (e.target.value.length > 150) {
      } else {
        this.setState({
          valueadditionalRequests: e.target.value,
          requestLength: e.target.value.length,
        });
      }
    }
  }
  nexttabtobeopen = (e) => {
    if (e.target.id === "Nextbtnreservation") {
      this.setState({ key: 2 });
    } else if (e.target.id === "agreeacontbtn") {
      this.setState({ key: 3 });
    }
  };
  radioselected = (e) => {
    this.setState({ radiobtnselected: e.target.value });
  };
  stoploading = () => {
    this.setState((prevState) => ({ loading: !prevState.loading }));
  };

  setupLaterCheckout = (e) => {
    // //console.log(this.props.stripe)
  };
  changeKeyCard = (keychange) => {
    this.setState((prevState) => ({ key: keychange }));
    window.scrollTo(0, 0);
  };

  changeUnauthorized = () => {
    this.setState({ Unauthorized: true });
  };
  onclickhere = () => {
    window.location =
      process.env.NODE_ENV === "development"
        ? `http://localhost:3001/USA/${
            this.props.match.params.Hname
          }/avail?CIdate=${
            this.props.location.search.split("?")[1].split("&")[0].split("=")[1]
          }&COdate=${
            this.props.location.search.split("?")[1].split("&")[1].split("=")[1]
          }&GA=${
            this.props.location.search.split("?")[1].split("&")[2].split("=")[1]
          }&GCH=${
            this.props.location.search.split("?")[1].split("&")[3].split("=")[1]
          }`
        : `https://www.reserv4me.com/USA/${
            this.props.match.params.Hname
          }/avail?CIdate=${
            this.props.location.search.split("?")[1].split("&")[0].split("=")[1]
          }&COdate=${
            this.props.location.search.split("?")[1].split("&")[1].split("=")[1]
          }&GA=${
            this.props.location.search.split("?")[1].split("&")[2].split("=")[1]
          }&GCH=${
            this.props.location.search.split("?")[1].split("&")[3].split("=")[1]
          }`;
    return false;
  };

  render() {
    const { roomobject, datagathered } = this.state;
    const { search } = this.props.location;
    //const duedate
    ////console.log("room object",roomobject)
    if (this.state.Unauthorized === true) {
      return (
        <div>
          <center>
            <h2>
              It seems your session timed out... click{" "}
              <a onClick={this.onclickhere} href="javascript:void(0);">
                here
              </a>{" "}
              to start again
            </h2>
            <h2>
              If your reservation was completed check for a confirmation in your
              email
            </h2>
            <h3>
              If you believe this message was an error... call the hotel phone
              number
            </h3>
          </center>
        </div>
      );
    } else {
      console.log("active key", this.state.key);
      return (
        <>
          <div>
            {/*Loading screen*/}
            <div
              hidden={this.state.loading === false ? true : false}
              className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  "
            >
              <div className="loading"></div>
              <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
                <p className="text-2xl text-white -translate-y-16">
                  Please be patient
                </p>
              </div>
            </div>
          </div>
          {/* Main reservation making payment and basic info */}
          <div className="grid  grid-flow-col grid-cols-12 grid-rows-2 lg:grid-rows-1 p-6  space-x-0 lg:space-x-4 ">
            <div className="col-span-full row-span-full row-start-3 lg:col-span-6 lg:row-span-full">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div
                  class="accordion-item border-t-0 border-l-0 border-r-0 
                rounded-none bg-white border border-gray-200"
                >
                  <h2 class="accordion-header mb-0" id="flush-headingOne">
                    <button
                      class={`accordion-button
                          relative
                          flex
                          items-center
                          w-full
                          py-4
                          px-5
                          text-base text-gray-800 text-left
                          bg-white
                          border-0
                          rounded-none
                          transition
                          focus:outline-none ${
                            this.state.key === 1 ? "" : "collapsed"
                          }`}
                      type="button"
                      /*data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"*/
                      onClick={() => this.handleSelected(1)}
                    >
                      Basic Info:
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class={`accordion-collapse border-0 collapse ${
                      this.state.key === 1 ? "show" : ""
                    }`}
                  >
                    <div class="accordion-body py-4 px-5">
                      <FormClientbuilder
                        valuefname={this.state.valuefname}
                        valuelname={this.state.valuelname}
                        valuephnum={this.state.valuephnum}
                        valueemail={this.state.valueemail}
                        valueemailconfirm={this.state.valueemailconfirm}
                        valueadditionalRequests={
                          this.state.valueadditionalRequests
                        }
                        requestLength={this.state.requestLength}
                        //emailconfirmchange={this.emailconfirmchange}
                        //emailconfirmstate={this.state.emailconfirmstate}

                        handlemeailchange={this.handlemeailchange}
                        validationState={this.validationState}
                        nexttabtobeopen={this.handleSelected}
                        //Validation of Name and phone number validation
                        Fnamevalidation={this.state.Fnamevalidation}
                        LnameValidation={this.state.LnameValidation}
                        PNumbValidation={this.state.PNumbValidation}
                      ></FormClientbuilder>
                    </div>
                  </div>
                </div>
                <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                  <h2 class="accordion-header mb-0" id="flush-headingTwo">
                    <button
                      class={`accordion-button
                        relative
                        flex
                        items-center
                        w-full
                        py-4
                        px-5
                        text-base text-gray-800 text-left
                        bg-white
                        border-0
                        rounded-none
                        transition
                        focus:outline-none ${
                          this.state.key === 2 ? "" : "collapsed"
                        }`}
                      type="button"
                      onClick={() => this.handleSelected(2)}
                    >
                      Policies:
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class={`accordion-collapse border-0 collapse ${
                      this.state.key === 2 ? "show" : ""
                    }`}
                  >
                    <div class="accordion-body py-6 px-5">
                      <ul>
                        <li className="space-x-4">
                          <h3>Check-in/Check-out info:</h3>
                          <span className="pl-2 block">
                            Check-in:{" "}
                            {this.state.datagathered
                              ? this.state.roomobject[0].timecheckin
                              : ""}{" "}
                            local time
                            <br />
                            Check-out:{" "}
                            {this.state.datagathered
                              ? this.state.roomobject[0].timecheckout
                              : ""}{" "}
                            local time
                          </span>
                        </li>
                        <li className="space-x-4">
                          <h3>Down Payment Policy:</h3>
                          <span
                            className="pl-2  "
                            hidden={
                              this.state.radiobtnselected === "Paynow"
                                ? false
                                : true
                            }
                          >
                            A down payment of{" "}
                            {this.state.datagathered
                              ? `${roomobject[0].dpamount} ${
                                  roomobject[0].typeofdp === "percentage"
                                    ? "%"
                                    : roomobject[0].typeofcancel === "night"
                                    ? " night + tax and fees "
                                    : ""
                                } `
                              : ""}{" "}
                            is due at time of booking
                          </span>
                          <span
                            className="pl-2 "
                            hidden={
                              this.state.radiobtnselected === "Paylater"
                                ? false
                                : true
                            }
                          >
                            A down payment of{" "}
                            {this.state.datagathered
                              ? `${roomobject[0].dpamount} ${
                                  roomobject[0].typeofdp === "percentage"
                                    ? "%"
                                    : roomobject[0].typeofcancel === "night"
                                    ? " night + tax and fees "
                                    : ""
                                } `
                              : ""}{" "}
                            will automatically be charged on{" "}
                            {this.state.datagathered
                              ? `${this.state.due_date.format(
                                  "MMM DD, YYYY"
                                )}  `
                              : ""}
                          </span>
                        </li>
                        <li className="space-x-4">
                          <h3>Cancelation Policy:</h3>
                          <span className="pl-2 block">
                            You can cancel free of charge up to{" "}
                            {this.state.datagathered
                              ? `${roomobject[0].freetime} ${roomobject[0].timeframe} `
                              : ""}
                            prior to your Check-in date outside the free
                            cancellation window a fee of{" "}
                            {this.state.datagathered
                              ? `${roomobject[0].cost} 
                                                     ${
                                                       roomobject[0]
                                                         .typeofcancel ===
                                                       "percentage"
                                                         ? "%"
                                                         : roomobject[0]
                                                             .typeofcancel ===
                                                           "night"
                                                         ? " night + tax and fees "
                                                         : ""
                                                     } `
                              : ""}{" "}
                            will be charged
                          </span>
                        </li>
                        <li className="space-x-4">
                          <h3>Parking Policy:</h3>
                          <span className="pl-2 block">
                            {this.state.datagathered
                              ? `${roomobject[0].parking_policy}  `
                              : ""}
                          </span>
                        </li>
                        <li className="space-x-4">
                          <h3>Pet Policy:</h3>
                          <span className="pl-2 block">
                            {this.state.datagathered
                              ? `${roomobject[0].pet_policy}  `
                              : ""}
                          </span>
                        </li>
                        <li className="space-x-4">
                          <h3>Additinal Policies:</h3>
                          <span className="pl-2 block">
                            {this.state.datagathered
                              ? `${roomobject[0].additional_pol}  `
                              : ""}
                          </span>
                        </li>
                      </ul>

                      <div className="flex justify-end">
                        <button
                          className="font-normal  bg-blue-500 text-white p-2 rounded inline "
                          id="agreeacontbtn"
                          onClick={() => {
                            this.handleSelected(3);
                          }}
                        >{`Agree & Continue`}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
                  <h2 class="accordion-header mb-0" id="flush-headingThree">
                    <button
                      class={`accordion-button
                        
                        relative
                        flex
                        items-center
                        w-full
                        py-4
                        px-5
                        text-base text-gray-800 text-left
                        bg-white
                        border-0
                        rounded-none
                        transition
                        focus:outline-none ${
                          this.state.key === 3 ? "" : "collapsed"
                        }`}
                      type="button"
                      onClick={() => this.handleSelected(3)}
                    >
                      Payment:
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className={`accordion-collapse border-0 collapse ${
                      this.state.key === 3 ? "show" : ""
                    }`}
                  >
                    <div class="accordion-body py-4 px-5">
                      <PaymenttypeForm
                        paymentroute={this.paymentroute}
                        radioselected={this.radioselected}
                        radiobtnselected={this.state.radiobtnselected}
                        fname={this.state.valuefname}
                        lname={this.state.valuelname}
                        phnum={this.state.valuephnum}
                        email={this.state.valueemail}
                        requests={this.state.valueadditionalRequests}
                        loading={this.stoploading}
                        setupLaterCheckout={this.setupLaterCheckout}
                        changekey={this.changeKeyCard}
                        Unauthorized={this.changeUnauthorized}
                        allow_setup_pay={this.state.allow_setup_pay}
                        due_date={this.state.due_date}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-full row-span-full row-start-1 lg:col-span-6 lg:row-span-full mb-4 lg:mb-0">
              {/*COntainer right side*/}
              <div className="grid grid-cols-12 shadow-3xl rounded-lg ">
                <div className="col-span-12 pb-4">
                  <RoomCarousel
                    roomphoto={
                      datagathered === false ? "" : roomobject[0].photourls
                    }
                    roomtype={
                      datagathered === false ? "" : roomobject[0].roomtype
                    }
                  />
                </div>
                <div className="grid grid-cols-12 col-span-12 px-6 pb-6">
                  <div className="grid grid-cols-12 col-span-12 ">
                    <span className="col-span-4 ">Room Type:</span>
                    <span className="col-span-8 lg:col-span-4 ">
                      {datagathered === false ? "" : roomobject[0].roomtype}
                    </span>
                  </div>
                  <div className=" grid grid-cols-12 col-span-12 lg:col-span-6">
                    <span className="col-span-4">From:</span>
                    <span className="col-span-8">
                      {search.split("?")[1].split("&")[0].split("=")[1]}{" "}
                    </span>
                  </div>
                  <div className="grid grid-cols-12 col-span-12 lg:col-span-6">
                    <span className="col-span-4">Until:</span>
                    <span className="col-span-8">
                      {search.split("?")[1].split("&")[1].split("=")[1]}{" "}
                    </span>
                  </div>
                  <div className="col-span-12 lg:col-span-4 ">
                    <p>Guest Count:</p>
                  </div>
                  <div className="col-span-6 lg:col-span-4 pl-4">
                    <p>{`${search.split("?")[1].split("&")[2].split("=")[1]}
                                Adult(s)`}</p>
                  </div>
                  <div className="col-span-6 lg:col-span-4">
                    <p>{`${search.split("?")[1].split("&")[3].split("=")[1]}
                                Children`}</p>
                  </div>
                  <div className="grid grid-cols-12 col-span-12 text-right pt-10">
                    <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">
                      Sub-Total:
                    </span>
                    <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$ ${
                      datagathered === false
                        ? ""
                        : parseFloat(
                            roomobject[0].totalcost.replace(/[^0-9\.-]+/g, "")
                          ).toFixed(2)
                    }`}</span>
                  </div>
                  <div className="grid grid-cols-12 col-span-12 text-right">
                    <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">{`Tax & fees:`}</span>
                    <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$ ${
                      datagathered === false ? "" : roomobject[0].taxcharge
                    }`}</span>
                  </div>
                  <div className="grid grid-cols-12 col-span-12 text-right">
                    <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">
                      Total:
                    </span>
                    <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$ ${
                      datagathered === false ? "" : roomobject[0].total_w_tax
                    }`}</span>
                  </div>
                  <div className="grid grid-cols-12 col-span-12 text-right">
                    <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">
                      {`Due ${
                        this.state.allow_setup_pay === true
                          ? this.state.radiobtnselected === "Paylater"
                            ? this.state.due_date.format("MMM DD, YYYY")
                            : "Today"
                          : "Today"
                      }`}
                      :
                    </span>
                    <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$ ${
                      datagathered === false
                        ? ""
                        : roomobject[0].typeofcancel === "percentage"
                        ? (
                            parseFloat(
                              roomobject[0].total_w_tax.replace(
                                /[^0-9\.-]+/g,
                                ""
                              )
                            ) *
                            (roomobject[0].dpamount / 100)
                          ).toFixed(2)
                        : roomobject[0].typeofcancel === "night"
                        ? (
                            parseFloat(roomobject[0].roomfirstnight) *
                            (1 + roomobject[0].taxrate / 100)
                          ).toFixed(2)
                        : ""
                    }`}</span>
                  </div>
                  <div className="grid grid-cols-12 col-span-12 text-right">
                    <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">
                      {`Due at Check in`}:
                    </span>
                    <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$ ${
                      datagathered === false
                        ? ""
                        : roomobject[0].typeofcancel === "percentage"
                        ? (
                            parseFloat(roomobject[0].total_w_tax).toFixed(2) -
                            (
                              parseFloat(
                                roomobject[0].total_w_tax.replace(
                                  /[^0-9\.-]+/g,
                                  ""
                                )
                              ) *
                              (roomobject[0].dpamount / 100)
                            ).toFixed(2)
                          ).toFixed(2)
                        : roomobject[0].typeofcancel === "night"
                        ? (
                            parseFloat(roomobject[0].total_w_tax).toFixed(2) -
                            (
                              parseFloat(roomobject[0].roomfirstnight) *
                              (1 + roomobject[0].taxrate / 100)
                            ).toFixed(2)
                          ).toFixed(2)
                        : ""
                    }`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
export default withRouter(ReservationMaking);
