import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import RoomCarousel from "./RoomCarousel";
import moment from "moment";
import ReactTooltip from "react-tooltip";

/*this class shows all  the rooms available for a certain hotel and will be 
contain:
-
-
-the link button to redirect to the reservation phase 

*/

class RoomDisplay extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      index: 0,
      direction: null,
    };
  }
  onSelectionReservation = (e) => {
    this.props.onSelecttion1(e);
  };

  render() {
    return (
      <>
        <div
          className=" grid grid-rows-6 grid-flow-col lg:grid-rows-3
         grid-cols-12  pr-6 pl-6 pt-6 pb-6 space-x-4 shadow-3xl rounded-lg 
         gap-y-6"
        >
          <div className="col-span-full row-span-6 lg:col-span-7 lg:row-span-3 xl:col-span-7 xl:row-span-6 ">
            <RoomCarousel
              roomphoto={this.props.roomphoto}
              roomtype={this.props.roomtype}
            />
          </div>
          <div className="col-span-full row-span-6 lg:col-span-5 lg:row-span-2 xl:col-span-4 xl:row-span-6">
            <div className="grid grid-cols-12 space-y-2">
              {/*Header title of room*/}
              <div className="col-span-4">
                <p>Room Type:</p>
              </div>
              <div className="col-span-8">
                <p>{this.props.roomtype}</p>
              </div>
              {/*Dates of CI and CO*/}
              <div className="col-span-6 ">
                <span className="block xl:inline">Check-in:</span>
                <span className="block xl:inline">{this.props.CIdate}</span>
              </div>
              <div className="col-span-6 ">
                <span className="block xl:inline">Check-Out:</span>
                <span className="block xl:inline">{this.props.COdate}</span>
              </div>
              {/*Occupancy */}
              <div class="col-span-12">
                <div className=" grid grid-cols-12">
                  <div className="col-span-4">
                    <p>Guest count:</p>
                  </div>
                  <div className="col-span-4">
                    <p>{`${this.props.ADg} Adults`}</p>
                  </div>
                  <div className="col-span-4">
                    <p>{`${this.props.CHg} Children`}</p>
                  </div>
                </div>
              </div>
              {/**Description */}
              <div className="col-span-12">
                <p>Description:</p>
              </div>
              <div className="col-span-12">
                <p>{this.props.roomdes}</p>
              </div>
              {/*Wifi etc logos */}
              <div className="col-span-12">
                <div className="grid grid-cols-12">
                  <div className="col-span-4 mx-auto">
                    <img
                      alt="wifi_logo"
                      data-tip
                      data-for="wifi_desc"
                      src="https://images.reserv4me.com/wifilogo_30H.png"
                    />
                    <ReactTooltip id="wifi_desc">
                      <span>
                        Type:{`${this.props.wifi_type}`}
                        <br></br>
                        {`${this.props.wifi_description}`}
                      </span>
                    </ReactTooltip>
                  </div>
                  <div className="col-span-4 mx-auto">
                    <img
                      alt="breakfast_logo"
                      data-tip
                      data-for="breakfast"
                      src={
                        this.props.breakfasttag_link !== null
                          ? this.props.breakfasttag_link
                          : null
                      }
                    />
                    <ReactTooltip id="breakfast">
                      <span>
                        Type:{`${this.props.breakfasttype}`}
                        <br></br>
                        {`${this.props.breakfast_description}`}
                      </span>
                    </ReactTooltip>
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <p className="font-light text-xl">
                  Free Cancellation Available*
                </p>
              </div>
              <div className="col-span-4">
                <p>
                  {new moment(this.props.COdate).diff(
                    new moment(this.props.CIdate),
                    "days"
                  ) >= this.props.minstay
                    ? this.props.isavail
                      ? this.props.exeedsocup
                        ? ""
                        : "Total:"
                      : ""
                    : ""}
                </p>
              </div>
              <div className="col-span-8">
                {new moment(this.props.COdate).diff(
                  new moment(this.props.CIdate),
                  "days"
                ) >= this.props.minstay
                  ? this.props.isavail
                    ? this.props.exeedsocup
                      ? ""
                      : `${this.props.roomoprice} + tax & fees`
                    : ""
                  : ""}
              </div>
            </div>
          </div>
          {/** Button and quick availability information */}
          <div className="col-span-full row-span-1 lg:col-span-5 lg:row-auto xl:col-span-1 xl:row-span-6">
            <div className="grid grid-cols-12 space-y-2 ">
              <div
                className={
                  this.props.isavail &&
                  !this.props.exeedsocup &&
                  new moment(this.props.COdate).diff(
                    new moment(this.props.CIdate),
                    "days"
                  ) >= this.props.minstay
                    ? "flex col-span-12  justify-center lg:justify-end xl:justify-center"
                    : "hidden"
                }
              >
                <button
                  className="font-normal  bg-blue-500 text-white p-2 rounded inline "
                  onClick={this.onSelectionReservation}
                  id={`button-${this.props.numberitem}`}
                >
                  Reserve Now
                </button>
              </div>
              <div
                className={
                  this.props.isavail
                    ? "hidden"
                    : "col-span-12 mx-auto lg:justify-end xl:justify-center"
                }
              >
                <p className="tracking-tight font-light text-lg ">Sold Out!</p>
              </div>
              <div
                className={
                  ((this.props.isavail ? true : false) &&
                    !this.props.exeedsocup) === true
                    ? "hidden"
                    : "col-span-12 text-center lg:text-right xl:text-center lg:justify-end xl:justify-center"
                }
              >
                <span className="tracking-tight font-light text-lg ">
                  Call us to check avail.
                </span>
              </div>
              <div
                className={
                  this.props.exeedsocup === false
                    ? "hidden"
                    : "col-span-12 text-center lg:text-right xl:text-center lg:justify-end xl:justify-center"
                }
              >
                <p className="tracking-tight font-light text-lg ">
                  Exceeds Occupancy
                </p>
              </div>
              <div className="col-span-12 text-center lg:text-right xl:text-center lg:justify-end xl:justify-center">
                <p
                  hidden={
                    this.props.numb_room_available > 2
                      ? true
                      : this.props.numb_room_available === 0
                      ? true
                      : false
                  }
                  className="tracking-tight font-light text-lg "
                >{` ${this.props.numb_room_available} left`}</p>
              </div>
              <div
                hidden={
                  this.props.isavail
                    ? new moment(this.props.COdate).diff(
                        new moment(this.props.CIdate),
                        "days"
                      ) >= this.props.minstay
                      ? true
                      : false
                    : true
                }
                className="col-span-12 text-center lg:text-right xl:text-center lg:justify-end xl:justify-center"
              >
                <p className="tracking-tight font-light text-lg ">{`Stay ${this.props.minstay} nights or more`}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(RoomDisplay);
