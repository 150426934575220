import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  linkAuthenticationElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { withRouter } from "react-router-dom";

//import { createBrowserHistory } from 'history';

//const history = createBrowserHistory();

const CheckoutFormLink = ({
  loading,
  RegNum,
  AmountDue,
  tripid,
  hotel_web_id,
  onCheckboxPolicyChanged,
  policiesunderstood,
  clientSecret,
  paymentStatusChange,
  paymentsuccessful,
  clientInfo,
  onSuccessfulPayment,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const { err, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000",
        payment_method_data: {
          billing_details: {
            // name: `${clientInfo.client_fname} ${clientInfo.client_lname}`,
            email: clientInfo.client_email,
          },
        },
      },
      redirect: "if_required",
    });
    if (err) {
      if (err.type === "card_error" || err.type === "validation_error") {
        //console.log("🚀 ~ handleSubmit ~ err:", err);
        // setMessage(error.message);
      } else {
        // setMessage("An unexpected error occurred.");
      }
      // handleError();
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      paymentStatusChange();
      onSuccessfulPayment(paymentIntent.id);

      // handleSuccess();
    } else {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        // console.log(
        //   "🚀 ~ stripe.retrievePaymentIntent ~ paymentIntent.status:",
        //   paymentIntent
        // );
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");

            break;
          case "processing":
            setMessage("Your payment is processing.");

            break;
          case "requires_payment_method":
            setMessage(
              paymentIntent.last_payment_error.message + "please try again."
            );

            break;
          default:
            setMessage("Something went wrong.");

            break;
        }
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="space-y-4">
      <div className="px-4">
        <p>Enter a card of your choice</p>
      </div>
      {message && (
        <div id="payment-message " className="flex">
          <p className=" mx-auto text-red-500 ">{`${message}`}</p>
        </div>
      )}

      <div className="px-10 py-5">
        <AddressElement options={{ mode: "billing" }} />
        {stripe && clientSecret && <PaymentElement />}
      </div>

      <div className="flex justify-end">
        <input
          className="rounded  border border-gray-400 shadow-inner"
          id="policies"
          type="checkbox"
          checked={policiesunderstood}
          onChange={(e) => onCheckboxPolicyChanged(e)}
        />
        <label for="Paylater" className="text-center">
          I have read and undertand all policies found below. I will abide to
          all and assume all financial obligations according to my card provider
        </label>
      </div>
      <div className="flex justify-end">
        {isLoading ? (
          <button
            className="font-normal disabled:bg-blue-500/50 bg-blue-500 text-white p-2 rounded inline-flex items-center px-4 py-2  "
            disabled>
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </button>
        ) : (
          <button
            disabled={policiesunderstood === true ? false : true}
            className="font-normal disabled:bg-blue-500/50 bg-blue-500 text-white p-2 rounded inline "
            onClick={handleSubmit}
            id="stripebtn">{`Pay Now`}</button>
        )}
      </div>
    </div>
  );
};

export default CheckoutFormLink;
