import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import { withRouter } from "react-router-dom";

class CardSetupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isemptycard: true,
      paymentsetupagreement: false,
      disableSaveCard: true,
    };
  }

  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    ////console.log("element", elements.getElement(CardElement), elements.getElement(CardElement)._invalid)

    let checklist = this.props.paymentroute(event);
    ////console.log("checklist", checklist)
    if (
      checklist === true &&
      elements.getElement(CardElement)._empty === false &&
      this.state.paymentsetupagreement === true
    ) {
      fetch(
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/setup-laterpay"
          : "https://apiv1.reserv4me.com/setup-laterpay",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: window.sessionStorage.getItem("token"),
          },
          body: JSON.stringify({
            hotelname: this.props.match.params.Hname,
          }),
        }
      )
        .then((data) => data.json())
        .then((res) => {
          ////console.log(res)

          if (res === "Unauthorized") {
            throw {
              Unauthorized: true,
              error: new Error(),
            };
          } else {
            return stripe.confirmCardSetup(res.client, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: `${this.props.fname} ${this.props.lname}`,
                  phone: this.props.phnum,
                  email: this.props.email,
                },
              },
            });
          }
        })
        .then((result) => {
          ////console.log("I stop by here",result)
          if (result.error) {
            // //console.log("error")
            this.props.loading();
            // //console.log("please fill card details")
            return { error: "error" };
            // Display result.error.message in your UI.
          } else {
            // //console.log(" no error")

            return fetch(
              process.env.NODE_ENV === "development"
                ? "http://localhost:3000/confirmpay"
                : "https://apiv1.reserv4me.com/confirmpay",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: window.sessionStorage.getItem("token"),
                },
                body: JSON.stringify({
                  payment_method: result.setupIntent.payment_method,
                  CIdate: this.props.location.search
                    .split("?")[1]
                    .split("&")[0]
                    .split("=")[1],
                  COdate: this.props.location.search
                    .split("?")[1]
                    .split("&")[1]
                    .split("=")[1],
                  hotelname: this.props.match.params.Hname,
                  ADg: this.props.location.search
                    .split("?")[1]
                    .split("&")[2]
                    .split("=")[1],
                  CHg: this.props.location.search
                    .split("?")[1]
                    .split("&")[3]
                    .split("=")[1],
                  roomid: this.props.location.search
                    .split("?")[1]
                    .split("&")[4]
                    .split("=")[1],
                  tripid: window.sessionStorage.getItem("trid"),
                  query: this.props.location.search.split("?")[1],
                  fname: this.props.fname,
                  lname: this.props.lname,
                  phnum: this.props.phnum,
                  email: this.props.email,
                  requests: this.props.requests,
                }),
              }
            );

            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
          }
        })
        .then((data) => {
          // //console.log("data result",data)
          return data.json();
        })
        .then((result) => {
          ////console.log("final results",result)
          if (result === "Unauthorized") {
            throw {
              Unauthorized: true,
              error: new Error(),
            };
          } else {
            window.sessionStorage.setItem("strLaterUsed", true);
            window.location = result;
          }
        })
        .catch((error) => {
          if (error.Unauthorized === true) {
            //window.location = result;
            ////console.log("You sessicon expired ")
            this.props.loading();
            this.props.Unauthorized();
          } else {
            this.props.changekey(3);
            this.setState((prevState) => ({
              isemptycard: !prevState.isemptycard,
            }));
          }

          ////console.log("yeah we have an error",error)
        });
    } else {
      //this.props.loading();
      if (checklist === false) {
        this.props.changekey(1);
      } else {
        this.props.loading();
        this.props.changekey(3);
        this.setState((prevState) => ({ isemptycard: false }));
      }

      ////console.log("the card field is empty")
    }
  };

  onCheckboxPaymentChanged = (e) => {
    ////console.log(e.target)
    this.setState((prevState) => ({
      paymentsetupagreement: !prevState.paymentsetupagreement,
      disableSaveCard: !prevState.disableSaveCard,
    }));
  };

  render() {
    console.log("Props", this.state.disableSaveCard, this.props);
    return (
      <form onSubmit={this.handleSubmit} id="strbtnlater" className="space-y-4">
        <div className="space-x-2 px-4">
          <input
            className="rounded  border border-gray-400 shadow-inner"
            id="paymentsetupagreement"
            type="checkbox"
            checked={this.state.paymentsetupagreement}
            onChange={this.onCheckboxPaymentChanged}
          />
          <label for="paymentsetupagreement">
            {`I understand and authorize my payment method to be charged automatically by ${window.sessionStorage.getItem(
              "hname"
            )}
                                on ${this.props.due_date.format(
                                  "MMM DD, YYYY"
                                )} for the downpayment of this reservation`}
          </label>
        </div>
        <div hidden={this.state.isemptycard}>
          <h4 className="text-red-500">
            Payment form is not valid or empty, please provide another payment
            form
          </h4>
        </div>

        <div className="px-10 py-5">
          <CardSection />
        </div>

        <div className="flex justify-end">
          <button
            className="font-normal disabled:bg-blue-500/50 bg-blue-500 text-white p-2 rounded inline "
            onClick={this.handleSubmit}
            id="strbtnlater"
            disabled={this.state.disableSaveCard === true ? true : false}
          >{`Continue & Review`}</button>
        </div>
      </form>
    );
  }
}

export default withRouter(CardSetupForm);
