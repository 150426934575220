import React from "react";

function FieldGroup({ validationState, id, label, help, type, ...props }) {
  return (
    <div className=" px-3 mb-4 md:mb-0">
      <label
        className="block text-black text-sm font-medium mb-1"
        htmlFor={label}
      >
        {label} <span className="text-red-600">*</span>
      </label>

      {type === "textarea" ? (
        <textarea
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner "
        ></textarea>
      ) : (
        <input
          id={id}
          type={type}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
          {...props}
        />
      )}

      {type === "textarea" ? (
        <p hidden={validationState} className=" text-sm mt-2 flex justify-end">
          {help}
        </p>
      ) : (
        <p hidden={validationState} className="text-red-500 text-sm mt-2">
          {help}
        </p>
      )}
    </div>
  );
}

const FormBuilder = ({
  valuefname,
  valuelname,
  valuephnum,
  valueemail,
  valueemailconfirm,
  valueadditionalRequests,
  requestLength,

  handlemeailchange,
  validationState,
  nexttabtobeopen,
  Fnamevalidation,
  LnameValidation,
  PNumbValidation,
  originalemailstate,
  toconfirmemailstate,
}) => {
  console.log(
    "🚀 ~ file: FormClientbuilder.js ~ line 55 ~ Fnamevalidation",
    Fnamevalidation,
    LnameValidation,
    PNumbValidation,
    validationState("original"),
    validationState("toconfirm")
  );

  return (
    <div className="mx-auto space-y-4">
      <FieldGroup
        id="formControlsFName"
        type="text"
        label="First Name"
        placeholder="First Name"
        required={true}
        value={valuefname}
        onChange={handlemeailchange}
        validationState={Fnamevalidation === "success" ? true : false}
        help={
          Fnamevalidation === null
            ? ""
            : "This field is required please add a First Name"
        }
      />
      <FieldGroup
        id="formControlsLName"
        type="text"
        label="Last Name"
        placeholder="Last Name"
        required={true}
        value={valuelname}
        onChange={handlemeailchange}
        validationState={LnameValidation === "success" ? true : false}
        help={
          LnameValidation === null
            ? ""
            : "This field is required please add a Last Name"
        }
      />
      <FieldGroup
        id="formControlsPhoneNumb"
        type="tel"
        label="Phone Number"
        placeholder="(123)-456-7890"
        required={true}
        value={valuephnum}
        onChange={handlemeailchange}
        validationState={PNumbValidation === "success" ? true : false}
        help={
          PNumbValidation === null
            ? ""
            : "This field is required please add a Phone Number"
        }
      />

      <FieldGroup
        id="formEmail"
        type="text"
        label="Email"
        placeholder="myemail@email.com"
        required={true}
        value={valueemail}
        onChange={handlemeailchange}
        validationState={
          validationState("original") === "success" ? true : false
        }
        help={
          PNumbValidation === null
            ? ""
            : "Enter email in the format myemail@email.com"
        }
      />

      <FieldGroup
        id="formConfirmEmail"
        type="text"
        label="Confirm Email"
        placeholder="myemail@email.com"
        required={true}
        value={valueemailconfirm}
        onChange={handlemeailchange}
        validationState={
          validationState("toconfirm") === "success" ? true : false
        }
        autoComplete="nope"
        help={
          PNumbValidation === null
            ? ""
            : "Enter email matching email to the one used above"
        }
      />
      <FieldGroup
        id="formAditionalRequest"
        type="textarea"
        label="Additional Request(s):"
        placeholder="Additional Requests"
        required={false}
        value={valueadditionalRequests}
        onChange={handlemeailchange}
        help={`${requestLength}/150`}
      />

      <div className="flex justify-end">
        <button
          className="font-normal  bg-blue-500 text-white p-2 rounded inline "
          id="Nextbtnreservation"
          onClick={() => {
            nexttabtobeopen(2);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FormBuilder;
