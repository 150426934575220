import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

const today = moment();

const initalState = {
  dateCI: moment(),
  dateCO: moment().add(1, "days"),
  GuestAdult: 1,
  GuestChild: 0,
};
class Dateavailability extends Component {
  constructor(props) {
    super(props);

    this.state = initalState;
  }
  componentDidMount() {
    const datePickers = document.getElementsByClassName(
      "react-datepicker__input-container"
    );
    Array.from(datePickers).forEach((el) =>
      el.childNodes[0].setAttribute("readOnly", true)
    );
  }

  OnChangedateCI = (dateCIentered) => {
    if (
      dateCIentered.diff(today, "days") >= 0 &&
      this.state.dateCO.diff(dateCIentered, "days") > 0
    ) {
      ////console.log("here")
      this.setState({ dateCI: dateCIentered });
    }
    if (this.state.dateCO.diff(dateCIentered, "days") <= 0) {
      ////console.log("here1", this.state.dateCO === initalState.dateCO)
      this.setState((prevState) => ({
        dateCI: prevState.dateCI.add(
          dateCIentered.diff(prevState.dateCI, "days") + 1,
          "days"
        ),
        dateCO: dateCIentered.add(1, "days"),
      }));
    }
  };
  OnChangedateCO = (dateCOenter) => {
    ////console.log("test", dateCOenter.month() === initalState.dateCO.month())
    if (dateCOenter > this.state.dateCO && dateCOenter > this.state.dateCI) {
      //dateCOenter.diff(this.state.dateCO,"days")>=0&&dateCOenter.diff(this.state.dateCI,"days")>0)
      this.setState({ dateCO: dateCOenter });
    } else {
      if (dateCOenter < this.state.dateCO && dateCOenter > today) {
        if (dateCOenter <= this.state.dateCI) {
          this.setState((prevState) => ({
            dateCI: prevState.dateCI.subtract(
              prevState.dateCI.diff(dateCOenter, "days") + 1,
              "days"
            ),
            dateCO: dateCOenter,
          }));
        } else if (dateCOenter > this.state.dateCI) {
          this.setState({ dateCO: dateCOenter });
        }
      } else if (dateCOenter < this.state.dateCO && dateCOenter <= today) {
        this.setState((prevState) => ({
          dateCI:
            dateCOenter.diff(prevState.dateCI, "days") > 0 ? today : today,
          dateCO: initalState.dateCO,
        }));
      }
    }
  };
  GuestSelectedAd = (direction, type) => {
    if (direction === "up") {
      if (type === "Adult") {
        this.setState((prevState) => ({
          GuestAdult: prevState.GuestAdult + 1,
        }));
      } else {
        this.setState((prevState) => ({
          GuestChild: prevState.GuestChild + 1,
        }));
      }
    } else {
      if (type === "Adult") {
        this.setState((prevState) => {
          return {
            GuestAdult:
              prevState.GuestAdult > 1
                ? prevState.GuestAdult - 1
                : prevState.GuestAdult,
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            GuestChild:
              prevState.GuestChild > 1
                ? prevState.GuestChild - 1
                : prevState.GuestChild,
          };
        });
      }
    }

    //this.setState({ GuestAdult: e.target.value });
  };

  GuestValueManual = (e) => {
    let tempvar = e.target;

    let textreg = /^-?\d*$/;

    if (textreg.test(tempvar.value) && tempvar.value !== "") {
      if (tempvar.id === "Adult") {
        this.setState((prevState) => ({
          GuestAdult: tempvar.value,
        }));
      } else {
        this.setState((prevState) => ({
          GuestChild: tempvar.value,
        }));
      }
    } else {
      console.log("empty");
      return null;
    }
  };

  render() {
    ////console.log(this.state.dateCI, this.state.dateCO)
    return (
      <>
        <div className="bg-gradient-to-r from-green-400 to-blue-500  pt-20 pb-20 px-4">
          <div
            className="container justify-center mx-auto drop-shadow-xl 
          bg-white  rounded-lg p-10 bg-opacity-75  border-blue-800
          max-w-6xl m-10 "
          >
            <div className="grid grid-cols-2 gap-4">
              <div
                className=" flex lg:justify-start 
             lg:pl-7 mb-4 col-span-2"
              >
                <h3 className="font-light text-2xl ">
                  Select dates for reservation:
                </h3>
              </div>
              <div className="block md:flex space-x-2 justify-end  ">
                <h4 className="font-light text-xl w-20 md:w-40 ">From:</h4>
                <DatePicker
                  selected={this.state.dateCI}
                  selectsStart
                  startDate={this.state.dateCI}
                  endDate={this.state.dateCO}
                  onChange={this.OnChangedateCI}
                  id="CI"
                  className=" rounded  text-center w-full md:w-40 lg:w-60 "
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"], // don't allow it to flip to be above
                    },
                    preventOverflow: {
                      enabled: true, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                      enabled: false, // turn off since needs preventOverflow to be enabled
                    },
                  }}
                />
              </div>
              <div className="block md:flex space-x-2 ">
                <h4 className="font-light text-xl w-20 md:w-40  ">Until:</h4>
                <DatePicker
                  selected={this.state.dateCO}
                  selectsEnd
                  startDate={this.state.dateCI}
                  endDate={this.state.dateCO}
                  onChange={this.OnChangedateCO}
                  id="CO"
                  className="rounded  text-center w-full md:w-40 lg:w-60"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"], // don't allow it to flip to be above
                    },
                    preventOverflow: {
                      enabled: true, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                      enabled: false, // turn off since needs preventOverflow to be enabled
                    },
                  }}
                />
              </div>
              <div className="block md:flex space-x-2 justify-end">
                <h4 className="font-light text-xl w-20 md:w-40 ">Adults:</h4>
                <div className="w-full md:w-40 lg:w-60 space-x-4  flex justify-center items-center">
                  <button
                    className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12  "
                    onClick={() => {
                      this.GuestSelectedAd("down", "Adult");
                    }}
                  >
                    -
                  </button>
                  <input
                    className="bg-white w-12 text-center font-medium text-xl rounded"
                    value={this.state.GuestAdult}
                    id="Adult"
                    onChange={this.GuestValueManual}
                  />
                  <button
                    className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12"
                    onClick={() => {
                      this.GuestSelectedAd("up", "Adult");
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="block md:flex space-x-2 ">
                <h4 className="font-light text-xl w-20 md:w-40 ">Children:</h4>
                <div className="w-full md:w-40 lg:w-60 space-x-4   flex justify-center items-center">
                  <button
                    className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12  "
                    onClick={() => {
                      this.GuestSelectedAd("down", "Child");
                    }}
                  >
                    -
                  </button>
                  <input
                    className="bg-white w-12 text-center border font-medium text-xl rounded"
                    value={this.state.GuestChild}
                    id="Child"
                    onChange={this.GuestValueManual}
                  />
                  <button
                    className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12"
                    onClick={() => {
                      this.GuestSelectedAd("up", "Child");
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="col-span-2 flex justify-end lg:pr-7 mt-4 max-w-6xl">
                <Link
                  to={{
                    pathname: `/${this.props.Location}/${this.props.Hname}/avail`,
                    search: `?CIdate=${this.state.dateCI.format(
                      "MM/DD/YYYY"
                    )}&COdate=${this.state.dateCO.format("MM/DD/YYYY")}&GA=${
                      this.state.GuestAdult
                    }&GCH=${this.state.GuestChild}`,
                  }}
                >
                  <button
                    id="btncheckavail"
                    className="font-normal text-xl bg-blue-500 text-white p-2 rounded "
                  >
                    Check Availability
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dateavailability;
