import React from 'react';

const NotFound=()=>{
    
    return(
        <div>
            <div className="container text-center">
                <h1>Status: 404</h1>
                <h2>Not Found</h2>
            </div>
        </div>
    )
}

export default NotFound;