import React, { Component } from "react";

class RoomCarousel extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null,
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  render() {
    //const { index, direction } = this.state;
    const { roomphoto, roomtype } = this.props;

    if (roomphoto === "") {
      return (
        <div className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  ">
          <div className="loading"></div>
          <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
            <p className="text-2xl text-white -translate-y-16">
              Please be patient
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id={`carouselDarkVariant${roomtype.replace(/\s/g, "")}`}
          className="carousel slide carousel-fade relative "
          data-bs-ride="carousel">
          {/*<!-- Indicators -->*/}

          <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
            {roomphoto.map((current, i) => {
              return (
                <button
                  data-bs-target={`#carouselDarkVariant${roomtype.replace(
                    /\s/g,
                    ""
                  )}`}
                  data-bs-slide-to={i}
                  className={i === 0 ? "active" : ""}
                  aria-current="true"
                  aria-label={`Slide ${i}`}></button>
              );
            })}
          </div>

          {/* <!-- Inner --> */}
          <div className="carousel-inner relative w-full overflow-hidden">
            {/* <!-- Single item --> */}
            {roomphoto.map((current, i) => {
              return (
                // <Carousel.Item key={roomphoto[i].photoname} >
                // <img src={roomphoto[i].photourl} className="RoomImages centerimg "></img>
                //     <Carousel.Caption>
                //         <h3>{roomphoto[i].photoname}</h3>
                //     </Carousel.Caption>
                // </Carousel.Item>

                <div
                  className={
                    i === 0
                      ? "carousel-item active relative float-left w-full"
                      : "carousel-item relative float-left w-full"
                  }>
                  <img
                    src={roomphoto[i].photourl}
                    className="block w-full rounded"
                    alt={roomphoto[i].photoname}
                  />
                  <div className="carousel-caption hidden md:block absolute text-center">
                    <h5 className="text-2xl font-semibold">
                      {roomphoto[i].photoname}
                    </h5>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <!-- Inner --> */}

          {/* <!-- Controls --> */}
          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target={`#carouselDarkVariant${roomtype.replace(
              /\s/g,
              ""
            )}`}
            data-bs-slide="prev">
            <span
              className="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target={`#carouselDarkVariant${roomtype.replace(
              /\s/g,
              ""
            )}`}
            data-bs-slide="next">
            <span
              className="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      );
    }
  }
}

export default RoomCarousel;
