import React from "react";
import icons from "../../img/sprite.svg";

const Contact_Bubble = (props) => {
  //console.log("hello phone", props);
  return (
    <>
      <div class="navi">
        <input
          type="checkbox"
          className="navi__checkbox"
          id="navi-toggle"
        ></input>
        <label for="navi-toggle" className="navi__button">
          <span class="navi__icon-position">
            <svg className="navi__icon">
              <use xlinkHref={`${icons}#icon-message`} />
            </svg>
          </span>
        </label>
        <div className="navi__background">&nbsp;</div>
        <nav className="navi__nav ">
          <ul className="navi__list">
            <li className="navi__item">
              <a href={`tel:${props.hphone}`} className="navi__link">
                {" "}
                <svg className="navi__icon">
                  <use xlinkHref={`${icons}#icon-phone`} />
                </svg>{" "}
                Call us{" "}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Contact_Bubble;
