import React, { Component } from 'react';
import Hotelbanner from './Hotelbanner';
import Unitsavailable from './Havailability/Unitsavailable';
import { withRouter } from 'react-router-dom';
import Mainfooter from './Footer/Mainfooter'

class Havailability extends Component {
    constructor(props){
        super(props);
        this.state={
            
        }
    }
    render(){
    return(
        <div>
            <Hotelbanner Hname={this.props.Hname} />
            <Unitsavailable/>
            <Mainfooter/>
        </div>
    )
    }
}

export default withRouter(Havailability);