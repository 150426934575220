import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class SucessPayment extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelected = this.handleSelected.bind(this);
    this.state = {
      key: 1,
      loading: false,
      roomobject: "",
      datagathered: false,
      reservationprops: {
        hotelname: "",
        CIdate: "",
        COdate: "",
        ADg: "",
        CHg: "",
        roomid: "",
        tripid: "",
        paymentId: "",
        token: "",
        payerId: "",
      },
      isreserved: false,
      notfound: false,
    };
  }
  handleSelected(key) {
    this.setState({ key });
  }
  finishpayment = () => {
    this.setState((prevState) => ({ loading: !prevState.loading }));

    fetch(
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/sucess/checkoutstipe"
        : "https://apiv1.reserv4me.com/sucess/checkoutstipe",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: window.sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          hotelname: this.props.location.search
            .split("?")[1]
            .split("&")[0]
            .split("=")[1],
          CIdate: this.props.location.search
            .split("?")[1]
            .split("&")[1]
            .split("=")[1],
          COdate: this.props.location.search
            .split("?")[1]
            .split("&")[2]
            .split("=")[1],
          ADg: this.props.location.search
            .split("?")[1]
            .split("&")[3]
            .split("=")[1],
          CHg: this.props.location.search
            .split("?")[1]
            .split("&")[4]
            .split("=")[1],
          roomid: this.props.location.search
            .split("?")[1]
            .split("&")[5]
            .split("=")[1],
          tripid: this.props.location.search
            .split("?")[1]
            .split("&")[6]
            .split("=")[1],
          paymentId: this.props.location.search
            .split("?")[1]
            .split("&")[7]
            .split("=")[1],
          token: this.props.location.search
            .split("?")[1]
            .split("&")[8]
            .split("=")[1],
          payerId: this.props.location.search
            .split("?")[1]
            .split("&")[9]
            .split("=")[1],
          fname: this.state.roomobject.tripinfo.clfname,
          lname: this.state.roomobject.tripinfo.cllname,
          phnum: this.state.roomobject.tripinfo.clphone,
          email: this.state.roomobject.tripinfo.clemail,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data === "done") {
          this.setState((prevState) => ({
            loading: !prevState.loading,
            isreserved: true,
          }));
        }
      });
  };

  componentDidMount() {
    if (this.state.datagathered === false) {
      fetch(
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/successinit"
          : "https://apiv1.reserv4me.com/successinit",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: window.sessionStorage.getItem("token"),
          },
          body: JSON.stringify({
            hotelname: this.props.location.search
              .split("?")[1]
              .split("&")[0]
              .split("=")[1],
            CIdate: this.props.location.search
              .split("?")[1]
              .split("&")[1]
              .split("=")[1],
            COdate: this.props.location.search
              .split("?")[1]
              .split("&")[2]
              .split("=")[1],
            ADg: this.props.location.search
              .split("?")[1]
              .split("&")[3]
              .split("=")[1],
            CHg: this.props.location.search
              .split("?")[1]
              .split("&")[4]
              .split("=")[1],
            roomid: this.props.location.search
              .split("?")[1]
              .split("&")[5]
              .split("=")[1],
            tripid: this.props.location.search
              .split("?")[1]
              .split("&")[6]
              .split("=")[1],
            paymentId: this.props.location.search
              .split("?")[1]
              .split("&")[7]
              .split("=")[1],
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (
            data !== undefined &&
            data !== "NotFound" &&
            data !== "Unauthorized"
          ) {
            //console.log(data);
            if (data.isBooked.isbooked === "false") {
              this.setState((prevState) => ({
                roomobject: data,
                datagathered: !prevState.datagathered,
              }));
            } else if (data.isBooked.isbooked === "true") {
              this.setState((prevState) => ({
                roomobject: data,
                datagathered: !prevState.datagathered,
                isreserved: true,
              }));
            }
          } else {
            this.setState((prevState) => ({ notfound: !prevState.notfound }));
          }
        });
    }
  }
  render() {
    // //console.log("test",this.props.location.state)
    return <h1>TEst</h1>;
  }
}

export default withRouter(SucessPayment);
