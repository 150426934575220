import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Roomlist from "./Roomlist";

class Unitsavailable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomobject: [],
      CIdate: "",
      COdate: "",
      ADg: "",
      CHg: "",
    };
  }
  obtaincorrectdata = () => {
    ////console.log("isit",window.sessionStorage.getItem('trid')===null)
    fetch(
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/reservation"
        : "https://apiv1.reserv4me.com/reservation",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          CIdate: this.props.location.search
            .split("?")[1]
            .split("&")[0]
            .split("=")[1],
          COdate: this.props.location.search
            .split("?")[1]
            .split("&")[1]
            .split("=")[1],
          hotelname: this.props.match.params.Hname,
          ADg: this.props.location.search
            .split("?")[1]
            .split("&")[2]
            .split("=")[1],
          CHg: this.props.location.search
            .split("?")[1]
            .split("&")[3]
            .split("=")[1],
          tripid:
            window.sessionStorage.getItem("trid") === null
              ? undefined
              : window.sessionStorage.getItem("trid"),
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        ////console.log("data",data)
        ////console.log("prosin",this.props)
        if (data !== undefined) {
          // //console.log("yeap",data)
          this.setState({ roomobject: data[0] });
          if (window.sessionStorage.getItem("trid") === null) {
            window.sessionStorage.setItem("trid", data[1].tripid);
          }
          //remember that when updating the DOM if we say use my old way
        } //which was to call the method inside the html code (onLoad)is a wrong approach since once called and set the new state we are "loading"
      }); //the DOM again do an infinite loop is created, thus is better to call the method from the componentdidmount() so that it gets called once.
  };
  componentDidMount() {
    const { search } = this.props.location;
    this.setState({ CIdate: search.split("?")[1].split("&")[0].split("=")[1] });
    this.setState({ COdate: search.split("?")[1].split("&")[1].split("=")[1] });
    this.setState({ ADg: search.split("?")[1].split("&")[2].split("=")[1] });
    this.setState({ CHg: search.split("?")[1].split("&")[3].split("=")[1] });
    //var today=moment();
    //var testtom=moment(`${today.format('YYYY-MM-DD')}T24:00:00`);
    ////console.log(testtom>today,testtom)
    //window.sessionStorage.setItem("hname",this.props.match.params.Hname);
    //if(this.state.CIdate)
    this.obtaincorrectdata();
  }
  onSelectionReservation = (e) => {
    let roomid = this.state.roomobject[e.target.id.split("-")[1]].roomtypeid;
    fetch(
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/reservation2"
        : "https://apiv1.reserv4me.com/reservation2",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            window.sessionStorage.getItem("token") === null
              ? undefined
              : window.sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          CIdate: this.props.location.search
            .split("?")[1]
            .split("&")[0]
            .split("=")[1],
          COdate: this.props.location.search
            .split("?")[1]
            .split("&")[1]
            .split("=")[1],
          hotelname: this.props.match.params.Hname,
          ADg: this.props.location.search
            .split("?")[1]
            .split("&")[2]
            .split("=")[1],
          CHg: this.props.location.search
            .split("?")[1]
            .split("&")[3]
            .split("=")[1],
          roomid: roomid,
          tripid: window.sessionStorage.getItem("trid"),
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        ////console.log(data)
        if (data !== undefined) {
          if (data[0].token) {
            window.sessionStorage.setItem("token", data[0].token);
            window.sessionStorage.setItem("trid", data[0].tripid);
          }
          this.props.history.push({
            pathname: `${this.props.location.pathname}/Reservation`,
            search: `${this.props.location.search}&rid=${roomid}`, //,
          });
        }
      });
  };
  render() {
    return (
      <div>
        <Roomlist
          roomobject={this.state.roomobject}
          CIdate={this.state.CIdate}
          COdate={this.state.COdate}
          ADg={this.state.ADg}
          CHg={this.state.CHg}
          onSelecttion={this.onSelectionReservation}
        />
      </div>
    );
  }
}

export default withRouter(Unitsavailable);
