import moment from "moment";
import React, { Component } from "react";

class FooterMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <footer>
          <div className="py-12 md:py-16">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
                <div className="md:col-span-4 lg:col-span-5">
                  <div className="mb-2">
                    {/* Logo */}
                    <a href="/" className="inline-block ">
                      <img
                        alt="Reserv4me Logo"
                        className="object-contain h-6"
                        src="https://res.cloudinary.com/dykrzfpex/image/upload/v1584917661/Reserv4me_logo_main_page_kwksny.png"
                      />
                    </a>
                  </div>
                  <div className="text-black">
                    We are a platform for aiding users and hotels make
                    reservations much easie, we strive to design web apps, give
                    you advice to help your business grow contact us to find out
                    more
                  </div>
                </div>
                {/* 2nd, 3rd and 4th blocks */}
                <div className="md:col-span-8 lg:col-span-7 grid sm:grid-cols-3 gap-8">
                  {/* 2nd block */}
                  <div className="text-sm">
                    <h6 className="text-blue-600 font-medium mb-1">Help</h6>
                    <ul>
                      <li className="mb-1">
                        <a
                          href="mailto:support@reserv4me.com"
                          className="text-black hover:underline transition duration-150 ease-in-out"
                        >
                          Contact Us
                        </a>
                      </li>
                      <li className="mb-1">
                        <a
                          href="https://twitter.com/R4MeSupport?s=20"
                          className="text-black hover:underline transition duration-150 ease-in-out"
                        >
                          Twitter Support
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* 3rd block */}
                  <div className="text-sm">
                    <h6 className="text-blue-600 font-medium mb-1">
                      Company Info
                    </h6>
                    <ul>
                      <li className="mb-1">
                        <a
                          href="https://www.engsysanalyticsllc.com/"
                          className="text-black hover:underline transition duration-150 ease-in-out"
                        >
                          About Us
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* 4th block */}
                  <div className="text-sm">
                    <h6 className="text-blue-600 font-medium mb-1">
                      {`Terms & Policies`}{" "}
                    </h6>
                    <ul>
                      <li className="mb-1">
                        <a
                          href="/terms-and-conditions"
                          className="text-black hover:underline transition duration-150 ease-in-out"
                        >
                          Terms of Use
                        </a>
                      </li>
                      <li className="mb-1">
                        <a
                          href="/privacy-policy"
                          className="text-black hover:underline transition duration-150 ease-in-out"
                        >
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Bottom area */}
              <div className="md:flex md:items-center md:justify-between">
                {/* Social links */}
                <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
                  <li>
                    <a
                      href="https://twitter.com/reserv4me?s=20"
                      className="flex justify-center items-center text-white  hover:bg-green-500 bg-blue-500 rounded-full transition duration-150 ease-in-out"
                      aria-label="Twitter"
                    >
                      <svg
                        className="w-8 h-8 fill-current"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                      </svg>
                    </a>
                  </li>
                </ul>

                {/* Copyrights note */}
                <div className="text-gray-400 text-sm mr-4">
                  &copy; {`2017-${moment().format("YYYY")}`} Engsys Analytics
                  LLC. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default FooterMain;
