import React from "react";
import CheckoutFormafterFail from "../Payments/Checkoutafterfail";
import { Elements, StripeProvider } from "react-stripe-elements";

const PaymenttypeFormafter = ({
  paymentroute,
  radioselected,
  radiobtnselected,
  fname,
  lname,
  phnum,
  email,
}) => {
  return (
    <>
      <div className="flex space-x-4 h-10">
        <img
          alt="mc"
          src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754834/mastercard-badge_ei9x5f.png"
        ></img>
        <img
          alt="visa"
          src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754836/visa-badge_fiwqwy.png"
        ></img>
        <img
          alt="discover"
          src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754834/discover-badge_pkcnsn.png"
        ></img>
        <img
          alt="em"
          src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754834/ae-badge_a5kr0n.png"
        ></img>
      </div>
      <div>
        <StripeProvider
          apiKey={
            process.env.NODE_ENV === "development"
              ? "pk_test_cAXwtQDWd9EnLQ1dYFDCety800ZWdqhQOz"
              : "pk_live_krEjGPuE0C0FA1kFgMsP5cJU00gym7y6of"
          }
        >
          <div className="example">
            <Elements>
              <CheckoutFormafterFail
                paymentroute={paymentroute}
                fname={fname}
                lname={lname}
                phnum={phnum}
                email={email}
              />
            </Elements>
          </div>
        </StripeProvider>
      </div>
    </>
  );
};

export default PaymenttypeFormafter;
