import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class StripeConnect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    ////console.log(this.props.match.params.Hname)
    return (
      <div>
        <center>
          <h1>
            Reserv4me is a new vision of how reservations management should be
            done
          </h1>
          <h3>
            using modern API to accelerate your processing power and waste less
            time
          </h3>
          {
            //<a href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_F207yIa4NSbZ0YZRoqSwYKTVeOvTkhHf&scope=read_write&state=${this.props.match.params.Hname}`}><Button id="stripeconnect" ><img src="https://images.reserv4me.com/blue-on-light@3x[1].png" width="250"></img></Button></a>
          }
        </center>
      </div>
    );
  }
}

export default withRouter(StripeConnect);
