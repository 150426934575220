import React, { Component } from "react";
import RoomDisplay from "./RoomDisplay";
import DatePicker from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

const today = moment();

const initalState = {
  dateCI: moment(),
  dateCO: moment().add(1, "days"),
  GuestAdult: 1,
  GuestChild: 1,
  loading: true,
};
class Roomlist extends Component {
  constructor(props) {
    super(props);

    this.state = initalState;
  }
  componentDidMount() {
    const datePickers = document.getElementsByClassName(
      "react-datepicker__input-container"
    );
    Array.from(datePickers).forEach((el) =>
      el.childNodes[0].setAttribute("readOnly", true)
    );
    this.setState((prevState) => ({
      dateCI: moment(
        this.props.location.search.split("?")[1].split("&")[0].split("=")[1]
      ),
      dateCO: moment(
        this.props.location.search.split("?")[1].split("&")[1].split("=")[1]
      ),
      GuestAdult: parseInt(
        this.props.location.search.split("?")[1].split("&")[2].split("=")[1]
      ),
      GuestChild: parseInt(
        this.props.location.search.split("?")[1].split("&")[3].split("=")[1]
      ),
      loading: !prevState.loading,
    }));
  }
  OnChangedateCI = (dateCIentered) => {
    if (
      dateCIentered.diff(today, "days") >= 0 &&
      this.state.dateCO.diff(dateCIentered, "days") > 0
    ) {
      ////console.log("here")
      this.setState({ dateCI: dateCIentered });
    }
    if (this.state.dateCO.diff(dateCIentered, "days") <= 0) {
      ////console.log("here1",this.state.dateCO===initalState.dateCO)
      this.setState((prevState) => ({
        dateCI: prevState.dateCI.add(
          dateCIentered.diff(prevState.dateCI, "days"),
          "days"
        ),
        dateCO: dateCIentered.add(1, "days"),
      }));
    }
  };
  OnChangedateCO = (dateCOenter) => {
    ////console.log("test",dateCOenter.month()===initalState.dateCO.month())
    if (dateCOenter > this.state.dateCO && dateCOenter > this.state.dateCI) {
      //dateCOenter.diff(this.state.dateCO,"days")>=0&&dateCOenter.diff(this.state.dateCI,"days")>0)
      this.setState({ dateCO: dateCOenter });
    } else {
      if (dateCOenter < this.state.dateCO && dateCOenter > today) {
        if (dateCOenter <= this.state.dateCI) {
          this.setState((prevState) => ({
            dateCI: prevState.dateCI.subtract(
              prevState.dateCI.diff(dateCOenter, "days") + 1,
              "days"
            ),
            dateCO: dateCOenter,
          }));
        } else if (dateCOenter > this.state.dateCI) {
          this.setState({ dateCO: dateCOenter });
        }
      } else if (dateCOenter < this.state.dateCO && dateCOenter <= today) {
        this.setState((prevState) => ({
          dateCI:
            dateCOenter.diff(prevState.dateCI, "days") > 0 ? today : today,
          dateCO: initalState.dateCO,
        }));
      }
    }
  };
  GuestSelectedAd = (direction, type) => {
    if (direction === "up") {
      if (type === "Adult") {
        this.setState((prevState) => ({
          GuestAdult: prevState.GuestAdult + 1,
        }));
      } else {
        this.setState((prevState) => ({
          GuestChild: prevState.GuestChild + 1,
        }));
      }
    } else {
      if (type === "Adult") {
        this.setState((prevState) => {
          return {
            GuestAdult:
              prevState.GuestAdult > 1
                ? prevState.GuestAdult - 1
                : prevState.GuestAdult,
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            GuestChild:
              prevState.GuestChild > 1
                ? prevState.GuestChild - 1
                : prevState.GuestChild,
          };
        });
      }
    }

    //this.setState({ GuestAdult: e.target.value });
  };
  GuestValueManual = (e) => {
    let tempvar = e.target;

    let textreg = /^-?\d*$/;

    if (textreg.test(tempvar.value) && tempvar.value !== "") {
      if (tempvar.id === "Adult") {
        this.setState((prevState) => ({
          GuestAdult: tempvar.value,
        }));
      } else {
        this.setState((prevState) => ({
          GuestChild: tempvar.value,
        }));
      }
    } else {
      console.log("empty");
      return null;
    }
  };

  render() {
    const { roomobject, CIdate, COdate, ADg, CHg, onSelecttion } = this.props;
    ////console.log("roomobj",roomobject)
    return (
      <>
        <div>
          <div
            id="loading"
            hidden={this.state.loading === false ? true : false}
          ></div>
          <main className="pt-10 px-4">
            <div className=" shadow-3xl max-w-6xl mx-auto rounded-lg ">
              <div
                className="container justify-center mx-auto   
                      rounded-lg p-10 pr-6  border-2 border-blue-800
                    max-w-6xl m-10  "
              >
                <div className="grid grid-cols-2 gap-4">
                  <div
                    className=" flex lg:justify-start 
             lg:pl-7 mb-4 col-span-2"
                  >
                    <h3 className="font-light text-2xl ">
                      Select dates for reservation:
                    </h3>
                  </div>
                  <div className="block md:flex space-x-2 justify-end  ">
                    <h4 className="font-light text-xl w-20 md:w-40 ">From:</h4>
                    <DatePicker
                      selected={this.state.dateCI}
                      selectsStart
                      startDate={this.state.dateCI}
                      endDate={this.state.dateCO}
                      onChange={this.OnChangedateCI}
                      id="CI"
                      className=" rounded shadow-md border-0 text-center w-full md:w-40 lg:w-60 "
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"], // don't allow it to flip to be above
                        },
                        preventOverflow: {
                          enabled: true, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                        },
                        hide: {
                          enabled: false, // turn off since needs preventOverflow to be enabled
                        },
                      }}
                    />
                  </div>
                  <div className="block md:flex space-x-2 ">
                    <h4 className="font-light text-xl w-20 md:w-40  ">
                      Until:
                    </h4>
                    <DatePicker
                      selected={this.state.dateCO}
                      selectsEnd
                      startDate={this.state.dateCI}
                      endDate={this.state.dateCO}
                      onChange={this.OnChangedateCO}
                      id="CO"
                      className="rounded shadow-md border-0 text-center w-full md:w-40 lg:w-60"
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"], // don't allow it to flip to be above
                        },
                        preventOverflow: {
                          enabled: true, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                        },
                        hide: {
                          enabled: false, // turn off since needs preventOverflow to be enabled
                        },
                      }}
                    />
                  </div>
                  <div className="block md:flex space-x-2 justify-end">
                    <h4 className="font-light text-xl w-20 md:w-40 ">
                      Adults:
                    </h4>
                    <div className="w-full md:w-40 lg:w-60 space-x-4  flex justify-center items-center">
                      <button
                        className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12  "
                        onClick={() => {
                          this.GuestSelectedAd("down", "Adult");
                        }}
                      >
                        -
                      </button>
                      <input
                        className="bg-white w-12 text-center font-medium text-xl rounded"
                        value={this.state.GuestAdult}
                        id="Adult"
                        onChange={this.GuestValueManual}
                      />
                      <button
                        className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12"
                        onClick={() => {
                          this.GuestSelectedAd("up", "Adult");
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="block md:flex space-x-2 ">
                    <h4 className="font-light text-xl w-20 md:w-40 ">
                      Children:
                    </h4>

                    <div className="w-full md:w-40 lg:w-60 space-x-4   flex justify-center items-center">
                      <button
                        className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12  "
                        onClick={() => {
                          this.GuestSelectedAd("down", "Child");
                        }}
                      >
                        -
                      </button>
                      <input
                        className="bg-white w-12 text-center border font-medium text-xl rounded"
                        value={this.state.GuestChild}
                        id="Child"
                        onChange={this.GuestValueManual}
                      />
                      <button
                        className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12"
                        onClick={() => {
                          this.GuestSelectedAd("up", "Child");
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="col-span-2 flex justify-end lg:pr-7 mt-4 max-w-6xl">
                    <Link
                      to={{
                        search: `?CIdate=${this.state.dateCI.format(
                          "MM/DD/YYYY"
                        )}&COdate=${this.state.dateCO.format(
                          "MM/DD/YYYY"
                        )}&GA=${this.state.GuestAdult}&GCH=${
                          this.state.GuestChild
                        }`,
                      }}
                    >
                      <button className="font-normal text-xl bg-blue-500 text-white p-2 rounded ">
                        Check Availability
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-6">
              {roomobject.map((current, i) => {
                return (
                  <RoomDisplay
                    CIdate={CIdate}
                    COdate={COdate}
                    ADg={ADg}
                    CHg={CHg}
                    roomtype={roomobject[i].roomtype}
                    roomdes={roomobject[i].description}
                    roomoprice={roomobject[i].totalcost}
                    exeedsocup={roomobject[i].exeedsocup}
                    roomphoto={roomobject[i].photourls}
                    numberitem={i}
                    isavail={roomobject[i].isavail}
                    onSelecttion1={onSelecttion}
                    minstay={roomobject[i].minimumstay}
                    breakfasttag_link={roomobject[i].breakfasttag_link}
                    breakfasttype={roomobject[i].breakfasttype}
                    breakfast_description={roomobject[i].breakfast_description}
                    wifi_type={roomobject[i].wifi_type}
                    wifi_description={roomobject[i].wifi_description}
                    numb_room_available={roomobject[i].numofroomavail}
                  />
                );
              })}
            </div>
          </main>
        </div>
      </>
    );
  }
}
export default withRouter(Roomlist);
