import React from "react";
import CheckoutForm from "../Payments/CheckoutForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutLaterForm from "../Payments/CheckoutFormSetupLater";

const PaymenttypeForm = ({
  paymentroute,
  radioselected,
  radiobtnselected,
  fname,
  lname,
  phnum,
  email,
  loading,
  requests,
  changekey,
  Unauthorized,
  allow_setup_pay,
  due_date,
}) => {
  console.log("is allowed", allow_setup_pay);
  return (
    <>
      <div className="flex space-x-4 h-10 pb-4">
        <img
          alt="mc"
          src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754834/mastercard-badge_ei9x5f.png"
        ></img>
        <img
          alt="visa"
          src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754836/visa-badge_fiwqwy.png"
        ></img>
        <img
          alt="discover"
          src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754834/discover-badge_pkcnsn.png"
        ></img>
        <img
          alt="ae"
          src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754834/ae-badge_a5kr0n.png"
        ></img>
      </div>
      <div className="space-y-10">
        {/**Pay Later */}
        <div
          hidden={allow_setup_pay === true ? false : true}
          className=" space-y-4 space-x-4"
        >
          <input
            id="Paylater"
            type="radio"
            name="radioGroup"
            value="Paylater"
            onClick={radioselected}
            checked={radiobtnselected === "Paylater" ? true : false}
            hidden={allow_setup_pay === true ? false : true}
          />
          <label for="Paylater" className="text-center">
            Pay Later
          </label>
          <div
            hidden={
              radiobtnselected === "Paynow" || allow_setup_pay === false
                ? true
                : false
            }
          >
            <CheckoutLaterForm
              paymentroute={paymentroute}
              fname={fname}
              lname={lname}
              phnum={phnum}
              email={email}
              loading={loading}
              requests={requests}
              changekey={changekey}
              Unauthorized={Unauthorized}
              due_date={due_date}
            />
          </div>
        </div>
        {/**Pay Now */}

        <div className="space-y-4 space-x-4">
          <input
            id="Paynow"
            type="radio"
            name="radioGroup"
            value="Paynow"
            onClick={radioselected}
            checked={radiobtnselected === "Paynow" ? true : false}
            className={allow_setup_pay === true ? "" : "hidden"}
          />
          <label hidden={allow_setup_pay === true ? false : true} for="Paynow">
            Pay Now
          </label>
          <div hidden={radiobtnselected === "Paylater" ? true : false}>
            <StripeProvider
              hidden={radiobtnselected === "Paylater" ? true : false}
              apiKey={
                process.env.NODE_ENV === "development"
                  ? "pk_test_cAXwtQDWd9EnLQ1dYFDCety800ZWdqhQOz"
                  : "pk_live_krEjGPuE0C0FA1kFgMsP5cJU00gym7y6of"
              }
            >
              <Elements>
                <CheckoutForm
                  paymentroute={paymentroute}
                  fname={fname}
                  lname={lname}
                  phnum={phnum}
                  email={email}
                  loading={loading}
                  requests={requests}
                />
              </Elements>
            </StripeProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymenttypeForm;
