import React, { Component } from "react";

class PaymenttypeForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSelected = this.handleSelected.bind(this);
    this.state = {
      key: 1,
    };
  }
  handleSelected(key) {
    this.setState({ key });
  }
  render() {
    return {
      /*
        <div>
            <h1>Test</h1>
            <Row>
                            <Col lg={7} >
                                <Tab.Container id="left-tabs-example" defaultActiveKey={1} onSelect={this.handleSelected} activeKey={this.state.key}>
                                    <Row className="clearfix">
                                        <Col className="outline AccordionSize">
                                            <Nav bsStyle="pills" stacked >
                                                <NavItem eventKey={1} className="Tabsopen">Basic Info:</NavItem>
                                            </Nav>
                                            <Tab.Content animation={false}>
                                                <Tab.Pane eventKey={1}>
                                                    <div className="outline pa2">
                                                        
                                                        Hello2
                                                        
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                <Col className="outline AccordionSize">
                                    <Nav bsStyle="pills" stacked>
                                        <NavItem eventKey={2} className="Tabsopen">Policies:</NavItem>
                                    </Nav>
                                    <Tab.Content animation={false}>
                                        <Tab.Pane eventKey={2}>
                                        <div>
                                            <ul>
                                                <li className="MarginsinsideTab">
                                                    Cancelation Policy:
                                                    <Row>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                                    Dolore minima sint deleniti mollitia aut beatae ab labore, 
                                                    veniam explicabo facere eveniet vitae at repudiandae. 
                                                    Animi eaque exercitationem id quasi omnis!
                                                    </Row>
                                                </li>
                                                <li className="MarginsinsideTab">
                                                    Parking Policy:
                                                    <Row>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
                                                    Dolore minima sint deleniti mollitia aut beatae ab labore, 
                                                    veniam explicabo facere eveniet vitae at repudiandae. 
                                                    Animi eaque exercitationem id quasi omnis!
                                                    </Row>
                                                </li>
                                            </ul>
                                        </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                                <Col className="outline AccordionSize">
                                    <Nav bsStyle="pills" stacked>
                                        <NavItem eventKey={3} className="Tabsopen">Payment</NavItem>
                                    </Nav>
                                    <Tab.Content animation={false}>
                                        <Tab.Pane eventKey={3}>
                                                    <div className="pa3">
                                                        Hello
                                                    </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                    
                </Row>
        </div>*/
    };
  }
}

export default PaymenttypeForm;
