import React, { Component } from "react";
import HotelMold from "./HotelMold";
import { Route, Switch, withRouter } from "react-router-dom";
import Havailability from "./components/Havailability";
import NotFound from "./components/NotFound";
import Reservationpage from "./components/Reservation/Reservationpage";
import SuccessfulRes from "./components/SuccessfulReservation/SuccessfulRes";
import SuccessPay from "./components/SuccessPayment/SucessPayment";
import SuccessStripe from "./components/SuccessPayment/SuccessPaymentStripe";
import StripeConnect from "./components/Stripe_connect/Stripe-connect";
//import Stripe_success from "./components/Stripe_connect/Stripe-success";
import TermsandConditions from "./components/Termandconditions/termsandconditions";
import PrivacyPolicy from "./components/Termandconditions/privacypolicy";
import WelcomeHome from "./components/Main_Home/Welcome";
import Payment_Url from "./components/Payment_url/payment_landing";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotelname: "",
    };
  }

  render() {
    //console.log("where am I", window.location.hostname);
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/payment"
            component={() => {
              return <Payment_Url />;
            }}
          />
          <Route
            exact
            path="/stripe/:Hname"
            component={() => {
              return <StripeConnect />;
            }}
          />
          <Route
            exact
            path="/:Location/:Hname"
            component={({ match }) => {
              return (
                <HotelMold
                  Hname={match.params.Hname}
                  Location={match.params.Location}
                />
              );
            }}
          />
          <Route
            exact
            path={`/:Location/:Hname/avail`}
            component={({ match }) => {
              return (
                <Havailability
                  Hname={match.params.Hname}
                  Location={match.params.Location}
                />
              );
            }}
          />
          <Route
            exact
            path={`/:Location/:Hname/avail/Reservation`}
            component={({ match }) => {
              return <Reservationpage Hname={match.params.Hname} />;
            }}
          />
          <Route
            exact
            path="/success"
            component={() => {
              return <SuccessPay />;
            }}
          />
          <Route
            exact
            path="/done"
            component={() => {
              return <SuccessfulRes />;
            }}
          />
          <Route
            exact
            path="/checkout"
            component={() => {
              return <SuccessStripe />;
            }}
          />
          {/*<Route exact path='/stripe-connect'component={({match})=>{return<Stripe_success Hname={match.params.Hname}/>}}/>*/}
          <Route
            exact
            path="/terms-and-conditions"
            component={() => {
              return <TermsandConditions />;
            }}
          />
          <Route
            exact
            path="/privacy-policy"
            component={() => {
              return <PrivacyPolicy />;
            }}
          />
          <Route
            exact
            path="/"
            component={() => {
              return <WelcomeHome />;
            }}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
