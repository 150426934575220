import React, { Component } from "react";
import ContactBubble from "../components/Contact_Bubble/Contact_Bubble";
import HeaderTop from "./header";

class Hotelbanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hwebsite: "",
      hlogo: "",
      hphone: "",
      h_url: "",
    };
  }
  hotelinfo = () => {
    fetch(
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/hinfo"
        : "https://apiv1.reserv4me.com/hinfo",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          hname: this.props.Hname,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(
          "🚀 ~ file: Hotelbanner.js ~ line 29 ~ Hotelbanner ~ .then ~ data",
          data
        );
        ////console.log("prosin",this.props)
        if (data !== undefined) {
          ////console.log("yeap")
          this.setState({
            hwebsite: data.hotelwebsite,
            hlogo: data.hotel_logo,
            hphone: data.hotelphone,
            h_url: data.hotel_url,
          });
          window.sessionStorage.setItem("hname", data.hotelwebsite);
          window.sessionStorage.setItem("hotelname", this.props.Hname);
          window.sessionStorage.setItem("hotelurl", data.hotel_url);

          //remember that when updating the DOM if we say use my old way
        } //which was to call the method inside the html code (onLoad)is a wrong approach since once called and set the new state we are "loading"
      }); //the DOM again do an infinite loop is created, thus is better to call the method from the componentdidmount() so that it gets called once.
  };
  componentWillMount() {
    this.hotelinfo();
  }
  render() {
    return (
      <>
        <HeaderTop
          logo={this.state.hlogo}
          hwebsite={this.state.hwebsite}
          hphone={this.state.hphone}
          h_url={this.state.h_url}
        />
        <ContactBubble hphone={this.state.hphone} />
      </>
    );
  }
}
export default Hotelbanner;
