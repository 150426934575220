import React from "react";

function FieldGroup({ validationState, id, label, help, type, ...props }) {
  return (
    <div className=" px-3 mb-4 md:mb-0">
      <label
        className="block text-black text-sm font-medium mb-1"
        htmlFor={label}
      >
        {label}
      </label>
      {type === "textarea" ? (
        <textarea
          id={id}
          {...props}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner "
          readOnly
        ></textarea>
      ) : (
        <input
          id={id}
          type={type}
          className="form-input w-full text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
          {...props}
          readOnly
        />
      )}
      {type === "textarea" ? (
        <p hidden={validationState} className=" text-sm mt-2 flex justify-end">
          {help}
        </p>
      ) : (
        <p hidden={validationState} className="text-red-500 text-sm mt-2">
          {help}
        </p>
      )}
    </div>
  );
}

const FormBuilder = ({
  roomobject,
  requestLength,
  clfname,
  cllname,
  clphone,
  clemail,
  cladditionalreq,
}) => {
  if (roomobject.tripinfo !== undefined) {
    return (
      <div className="mx-auto ">
        <FieldGroup
          id="formControlsFName"
          type="text"
          label="First Name"
          placeholder="First Name"
          required={true}
          value={clfname}
        />
        <FieldGroup
          id="formControlsLName"
          type="text"
          label="Last Name"
          placeholder="Last Name"
          required={true}
          value={cllname}
        />
        <FieldGroup
          id="formControlsPhoneNumb"
          type="tel"
          label="Phone Number"
          placeholder="(123)-456-7890"
          required={true}
          value={clphone}
        />
        <FieldGroup
          id="formEmail"
          type="text"
          label="Email"
          placeholder="myemail@email.com"
          required={true}
          value={clemail}
        />
        <FieldGroup
          id="formAditionalRequest"
          type="textarea"
          label="Additional Request(s):"
          placeholder="Additional Requests"
          required={true}
          value={cladditionalreq}
          help={`${cladditionalreq.length}/150`}
        />
      </div>
    );
  } else {
    return (
      <div className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  ">
        <div className="loading"></div>
        <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
          <p className="text-2xl text-white -translate-y-16">
            Please be patient
          </p>
        </div>
      </div>
    );
  }
};

export default FormBuilder;
