import React from "react";
import Hotelbanner from "./components/Hotelbanner";
import Dateavailability from "./components/Dateavailability/Dateavailability";
import Mainfooter from "./components/Footer/Mainfooter";

const HotelMold = ({ Hname, Location }) => {
  return (
    <>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <Hotelbanner Hname={Hname} />
        <main className="grow">
          {/*  Page illustration */}
          <div className="relative mt-20" aria-hidden="true">
            <Dateavailability Location={Location} Hname={Hname} />
          </div>
        </main>
        <Mainfooter />

        <script>
          {(function () {
            var s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://app.termly.io/embed.min.js";
            s.id = "b706fbbb-af03-46e4-a1bd-f2746bed40bd";
            s.setAttribute("data-name", "termly-embed-banner");
            var x = document.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          })()}
        </script>
      </div>
    </>
  );
};

export default HotelMold;
