import React, { Component } from "react";

class TermsandConditions extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    return (
      <div>
        <center>
          <h1>{`Terms & Conditions`}</h1>
        </center>
        <br></br>
        <br></br>

        <center>
          <div
            name="termly-embed"
            data-id="8b4c2c78-e11a-40a4-9f5f-e0a7f6468057"
            data-type="iframe"
          ></div>
          <script type="text/javascript">
            {(function (d, s, id) {
              var js,
                tjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s);
              js.id = id;
              js.src = "https://app.termly.io/embed-policy.min.js";
              tjs.parentNode.insertBefore(js, tjs);
            })(document, "script", "termly-jssdk")}
          </script>
        </center>
      </div>
    );
  }
}

export default TermsandConditions;
