export default [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      {
        saturation: "32",
      },
      {
        lightness: "-3",
      },
      {
        visibility: "on",
      },
      {
        weight: "1.18",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [
      {
        //visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      {
        saturation: "-70",
      },
      {
        lightness: "14",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        saturation: "100",
      },
      {
        lightness: "-14",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
      {
        lightness: "12",
      },
    ],
  },
];

// export default [
//   {
//     featureType: "water",
//     stylers: [
//       {
//         visibility: "on",
//       },
//       {
//         color: "#b5cbe4",
//       },
//     ],
//   },
//   {
//     featureType: "administrative",
//     elementType: "labels",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "landscape",
//     stylers: [
//       {
//         color: "#efefef",
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "road.highway",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#83a5b0",
//       },
//     ],
//   },
//   {
//     featureType: "road.arterial",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#bdcdd3",
//       },
//     ],
//   },
//   {
//     featureType: "road.local",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#ffffff",
//       },
//     ],
//   },
//   {
//     featureType: "poi.park",
//     elementType: "geometry",
//     stylers: [
//       {
//         color: "#e3eed3",
//       },
//     ],
//   },
//   {
//     featureType: "administrative",
//     stylers: [
//       {
//         visibility: "on",
//       },
//       {
//         lightness: 33,
//       },
//     ],
//   },
//   {
//     featureType: "road",
//   },
//   {
//     featureType: "poi.park",
//     elementType: "labels",
//     stylers: [
//       {
//         visibility: "on",
//       },
//       {
//         lightness: 20,
//       },
//     ],
//   },
//   {},
//   {
//     featureType: "road",
//     stylers: [
//       {
//         lightness: 20,
//       },
//     ],
//   },
// ];
