import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { withRouter } from "react-router-dom";
//import { createBrowserHistory } from 'history';

//const history = createBrowserHistory();
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

class CheckoutFormafterFail extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(e) {
    let { token } = await this.props.stripe.createToken({
      name: this.props.location.search
        .split("?")[1]
        .split("&")[0]
        .split("=")[1],
    });
    fetch(
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/checkoutstipe"
        : "https://apiv1.reserv4me.com/checkoutstipe",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: window.sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          token: token.id, //from stripe
          CIdate: this.props.location.search
            .split("?")[1]
            .split("&")[1]
            .split("=")[1],
          COdate: this.props.location.search
            .split("?")[1]
            .split("&")[2]
            .split("=")[1],
          hotelname: this.props.location.search
            .split("?")[1]
            .split("&")[0]
            .split("=")[1],
          ADg: this.props.location.search
            .split("?")[1]
            .split("&")[3]
            .split("=")[1],
          CHg: this.props.location.search
            .split("?")[1]
            .split("&")[4]
            .split("=")[1],
          roomid: this.props.location.search
            .split("?")[1]
            .split("&")[5]
            .split("=")[1],
          tripid: window.sessionStorage.getItem("trid"),
          query: this.props.location.search.split("?")[1],
          fname: this.props.fname,
          lname: this.props.lname,
          phnum: this.props.phnum,
          email: this.props.email,
        }),
      }
    )
      .then((data) => data.json())
      .then((res) => {
        window.sessionStorage.setItem("tokenStr", token.id);
        window.sessionStorage.setItem("StrpUse", true);
        //window.location = res;
        this.props.paymentroute();
      });

    ////console.log(token)
  }

  render() {
    ////console.log(this.props)
    return (
      <div className="space-y-4">
        <div className="px-4">
          <p>Enter a card of your choice</p>
        </div>
        <div className="px-10 py-5">
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>

        <div className="flex justify-end">
          <button
            className="font-normal  bg-blue-500 text-white p-2 rounded inline "
            onClick={this.submit}
            id="stripebtn"
          >{`Confirm & Pay`}</button>
        </div>
      </div>
    );
  }
}

export default withRouter(injectStripe(CheckoutFormafterFail));
